import { mealListElement } from "./meals";
import { Meal } from "./types";

declare const process: {
  env: {
    SERVER: string;
  };
};

let meals: Meal[];
const appElement = document.getElementById("app");

async function start() {
  await fetchMeals();
  appElement?.appendChild(mealListElement(meals));
}

function renderMeals(meals: Meal[]) {
  document.getElementById("meals")?.replaceWith(mealListElement(meals));
}

async function fetchMeals() {
  meals = await fetch(process.env.SERVER + "/meals").then((response) =>
    response.json()
  );
}

export function onCategoryClicked(ev: MouseEvent) {
  const el = ev.currentTarget as HTMLSpanElement;
  const category = el.textContent;
  if (category) {
    renderMeals(
      meals.filter((meal) => {
        return meal.categories.indexOf(category) > -1;
      })
    );
  }
}

start();
