import { Meal } from "./types";
import { onCategoryClicked } from "./app";

export function mealListElement(meals: Meal[]): HTMLUListElement {
  const list = document.createElement("ul");
  list.id = "meals";

  for (let meal of meals.sort((a, b) => a.name.localeCompare(b.name))) {
    if (meal.name.length > 0) {
      list.append(mealElement(meal.name, meal.categories));
    }
  }
  return list;
}

function mealElement(name: string, categories: string[]): HTMLLIElement {
  const mealEl = document.createElement("li");
  mealEl.textContent = name;
  categories.forEach((value) => mealEl.append(categoryElement(value)));
  return mealEl;
}

function categoryElement(name: string): HTMLSpanElement {
  const categoryEl = document.createElement("span");
  categoryEl.textContent = name;
  categoryEl.className = "meal-category ";
  categoryEl.onclick = onCategoryClicked;
  return categoryEl;
}
